import React from "react";
import { legalQuery } from "types/graphql";
import { graphql } from "gatsby";
import TitleIntro from "components/sections/titleIntro";
import {Seo} from "components/seo";

const privacyPage = ({ data }: { data: legalQuery }) => {
  const {
    allMarkdownRemark: {
      edges: {
        0: {
          node: { frontmatter, html },
        },
      },
    },
  } = data;

  return (
    <>
      <TitleIntro title={frontmatter.title as string} />
      <section className="container mb-16 lg:mb-28 lg:px-52">
        <div
          className="job-content"
          dangerouslySetInnerHTML={{ __html: html }}
        />
      </section>
    </>
  );
};
export default privacyPage;

export const pageQuery = graphql`
  query {
    allMarkdownRemark(
      filter: { fileAbsolutePath: { regex: "/privacy/" } }
      sort: { order: ASC, fields: fileAbsolutePath }
    ) {
      edges {
        node {
          html
          frontmatter {
            title
            metaTitle
            metaDescription
            metaImage
          }
        }
      }
    }
  }
`;
